import { SignRequest } from "../../../interfaces/SignRequest";

export const DocumentEndpoints = {
  getDocumentList: (
    pageAll: number,
    pageCompleted: number,
    pagePending: number,
    pageInProgress: number,
    pageSize: number,
    documentNameSearch?: string,
  ) => {
    const params = new URLSearchParams({
      pageAll: pageAll.toString(),
      pagePending: pagePending.toString(),
      pageCompleted: pageCompleted.toString(),
      pageInProgress: pageInProgress.toString(),
      pageSize: pageSize.toString(),
    });

    if (documentNameSearch != null && documentNameSearch.trim() !== "") {
      params.append("documentNameSearch", documentNameSearch.trim());
    }

    return `Document/GetDocumentList?${params.toString()}`;
  },
  SearchDocumentsByDocumentName: (
    search: string,
    page: number,
    pageSize: number,
  ) =>
    `Document/SearchDocumentsByDocumentName?documentName=${search}&page=${page}&pageSize=${pageSize}`,
  getDocumentStatus: (signProcessId: string | undefined) =>
    `Document/GetDocumentStatus?SignProcessIdRequest=${signProcessId}`,
  getFile: (signProcessId: string) =>
    `Document/GetFile?documentId=${signProcessId}`,
  sign: () => `Document/Sign`,
};
export const SecurityEndpoints = {
  refreshToken: (refreshTokenValue: string) =>
    `Security/RefreshToken?refreshTokenValue=${refreshTokenValue}`,

  externalLogin: (externalId: string) =>
    `Security/ExternalLogin?externalId=${externalId}`,

  login: () => "Security/Login",
  getMe: () => "Security/GetMe",
  changeOrganization: () => "Security/ChangeOrganization",
};
