import React, { useState } from 'react'
import Button from '../ui/Button';
import InputField from '../ui/InputField';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DocumentEndpoints } from '../../api/services/documentService/config';
import api from '../../api/Axios';
import { SignRequest } from '../../interfaces/SignRequest';
import { useDocumentosContext } from '../../context/DocumentosContext';

interface ModalFirmaProps {
    isOpen: boolean;
    onClose: () => void;
    onSign: () => void;
    selectedDocuments: string[];
}

export const ModalFirma = ({ isOpen, onClose, onSign, selectedDocuments }: ModalFirmaProps) => {
    const [usuario, setUsuario] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [pin, setPin] = useState('');
    const { refetchPage } = useDocumentosContext()
    const signMutation = useMutation({
        mutationFn: (signRequest: SignRequest) => {
            return api.post(DocumentEndpoints.sign(), signRequest)
        },
        onSuccess: () => {
            onSign()
            onClose()
            refetchPage()
        },
        onError: (error) => {
            console.error('Error signing documents:', error)
            // Handle error (e.g., show error message to user)
        },
    })

    const handleSign = () => {
        const signRequest: SignRequest = {
            signatureProcessIds: selectedDocuments,
            userName: usuario,
            password: contrasena,
            pin: pin,
        }
        signMutation.mutate(signRequest)
    }


    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center ">
            <div className="bg-white  p-6 rounded-lg w-96">
                <h2 className="text-xl font-bold mb-4">Firmar
                    <span className='text-primary'>{` ${selectedDocuments.length} ${selectedDocuments.length === 1 ? 'documento' : 'documentos'}`}</span>
                </h2>
                <div className='flex flex-col gap-4'>
                    <InputField placeholder='Usuario' type='text' value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                    <InputField placeholder='Contraseña' type='password' value={contrasena} onChange={(e) => setContrasena(e.target.value)} />
                    <InputField placeholder='PIN' type='password' value={pin} onChange={(e) => setPin(e.target.value)} />
                </div>
                <div className="flex justify-end mt-6 gap-4">
                    <Button
                        onClick={onClose}
                        text='Cancelar'
                        bgColor='bg-white'
                        textColor='text-primary'
                        hasBorder={true}
                    />
                    <Button
                        onClick={handleSign}
                        text='Firmar'
                        isDisabled={selectedDocuments.length === 0 && signMutation.isPending}
                    />
                </div>

            </div>
        </div>
    );
}
