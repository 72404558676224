import React from 'react'
import { formatearFecha } from '../../utils/dates/formatearFecha';
import { ItemListaDocumentos } from './ItemListaDocumentos';
import { DocumentBase } from '../../interfaces/DocumentsByUserRequest';
import { FilterDocument } from '../../context/DocumentosContext';

interface DocumentoGroupByFechaProps {
    fecha: string;
    documentos: FilterDocument[];
    selectedDocuments?: string[];
    onDocumentSelect?: (documentId: string) => void;
}

export const DocumentoGroupByFecha = ({ fecha, documentos, selectedDocuments, onDocumentSelect }: DocumentoGroupByFechaProps) => {
    return (
        <div className='mb-4'>
            <h2 className='text-text-secondary font-semibold text-base mb-2'>Publicado el {formatearFecha(fecha)}</h2>
            <ul className='flex flex-col gap-2'>
                {documentos.map((doc, index) => (
                    <ItemListaDocumentos key={doc.id}
                        documento={doc}
                        isSelected={selectedDocuments && selectedDocuments.includes(doc.id)}
                        onDocumentSelect={onDocumentSelect} />
                ))}
            </ul>
        </div>
    )
}
