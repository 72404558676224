
import { FilterDocument, useDocumentosContext } from '../../context/DocumentosContext';
import { DocumentBase } from '../../interfaces/DocumentsByUserRequest';
import { DocumentoGroupByFecha } from './DocumentoGroupByFecha';

interface ListaDocumentosProps {
    selectedDocuments?: string[]; // Propiedad opcional
    onDocumentSelect?: (documentId: string) => void; // Propiedad opcional
}

export const ListaDocumentos = ({ selectedDocuments, onDocumentSelect }: ListaDocumentosProps) => {
    const { documentosFiltrados } = useDocumentosContext();
    const agruparPorFecha = (documents: FilterDocument[]) => {
        return documents.reduce<{ [fecha: string]: FilterDocument[] }>((acc, doc) => {
            // Extraer solo la parte de la fecha (YYYY-MM-DD)
            const fecha = new Date(doc.creationDate).toISOString().split('T')[0];

            // Si la fecha no existe en el acumulador, inicializa un array
            if (!acc[fecha]) {
                acc[fecha] = [];
            }
            // Agrega el documento al array correspondiente a esa fecha
            acc[fecha].push(doc);
            return acc;
        }, {});
    };
    // Agrupar documentos
    const documentosAgrupados = agruparPorFecha(documentosFiltrados || []);
    return (
        <div className='md: max-w-screen-lg items-center '>
            {Object.entries(documentosAgrupados).map(([fecha, docs]) => (
                <DocumentoGroupByFecha key={fecha}
                    fecha={fecha}
                    documentos={docs}
                    selectedDocuments={selectedDocuments}
                    onDocumentSelect={onDocumentSelect} />
            ))}
        </div>
    )
}


