
interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  disabled?: boolean
}


export const Checkbox = ({ label, disabled, className, ...props }: CheckboxProps) => {
  return (
    <label className={`flex items-center gap-2  ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}${className}`}>
      <div className="relative flex items-center justify-center">
        <input
          type="checkbox"
          className={`peer  appearance-none border-2 size-6 border-slate-200 rounded-md 
             ${disabled
              ? ' bg-slate-200'
              : ' checked:bg-primary checked:border-primary-light'
            }
            ${!disabled && 'hover:border-primary-light'}`}
          disabled={disabled}
          {...props}
        />
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute pointer-events-none opacity-0 peer-checked:opacity-100"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.41603 0.37592C9.76067 0.605684 9.8538 1.07134 9.62404 1.41598L4.62404 8.91598C4.4994 9.10294 4.2975 9.22417 4.0739 9.24631C3.8503 9.26845 3.62855 9.18917 3.46967 9.03029L0.46967 6.03029C0.176777 5.73739 0.176777 5.26252 0.46967 4.96963C0.762563 4.67673 1.23744 4.67673 1.53033 4.96963L3.88343 7.32273L8.37596 0.583932C8.60573 0.239286 9.07138 0.146156 9.41603 0.37592Z"
            fill="white"
          />
        </svg>
      </div>
      {label && <span className='text-text-secondary whitespace-nowrap overflow-hidden text-ellipsis'>{label}</span>}

    </label >

  )
}