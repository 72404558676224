import { createContext, useContext, useEffect, useState } from "react";

export interface AppContextType {
    archivosPendientes:number|undefined;
    archivosCompletados:number|undefined;
    actualizarArchivos:()=>void
}

export const AppContext = createContext<AppContextType>({
    archivosPendientes: 10,
    archivosCompletados: 20,
    actualizarArchivos: () => {}
});

export const AppContextProvider = ({children}:any) => {

    const [archivosPendientes, setArchivosPendientes] = useState<number|undefined>(10);
    const [archivosCompletados, setArchivosCompletados] = useState<number|undefined>(20);

    const actualizarArchivos = () => {
        setArchivosCompletados(Math.round(Math.random()*100));
        setArchivosPendientes(Math.round(Math.random()*100));
    }

    return (
        <AppContext.Provider value={{archivosCompletados, archivosPendientes, actualizarArchivos}}>
            {children}
        </AppContext.Provider>
    )
}

export const useAppContext = () => {

    const context = useContext(AppContext);

    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppContextProvider');
    }
    return context;
}
