import React from 'react'

interface OrganizationIconProps {
    size?: number
    color?: string
    className?: string
}

export const OrganizationIcon: React.FC<OrganizationIconProps> = ({
    size = 18,
    color = "#64758B",
    className = ""
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            aria-hidden="true"
        >
            <path
                d="M13.9999 13.5993C14.2062 13.6163 14.4148 13.625 14.6255 13.625C15.4991 13.625 16.3378 13.4756 17.1175 13.201C17.1228 13.1347 17.1255 13.0677 17.1255 13C17.1255 11.6193 16.0062 10.5 14.6255 10.5C14.1025 10.5 13.617 10.6606 13.2156 10.9352M13.9999 13.5993C14 13.6079 14 13.6164 14 13.625C14 13.8125 13.9897 13.9976 13.9696 14.1797C12.5056 15.0197 10.8089 15.5 9 15.5C7.19111 15.5 5.49439 15.0197 4.03042 14.1797C4.01032 13.9976 4 13.8125 4 13.625C4 13.6165 4.00002 13.6079 4.00006 13.5994M13.9999 13.5993C13.995 12.6188 13.7079 11.7051 13.2156 10.9352M13.2156 10.9352C12.3273 9.54598 10.7712 8.625 9 8.625C7.22899 8.625 5.67304 9.54576 4.78474 10.9347M4.78474 10.9347C4.38352 10.6604 3.8983 10.5 3.37561 10.5C1.9949 10.5 0.87561 11.6193 0.87561 13C0.87561 13.0677 0.8783 13.1347 0.883577 13.201C1.66327 13.4756 2.502 13.625 3.37561 13.625C3.5859 13.625 3.79416 13.6163 4.00006 13.5994M4.78474 10.9347C4.29225 11.7047 4.00499 12.6187 4.00006 13.5994M11.5 3.625C11.5 5.00571 10.3807 6.125 9 6.125C7.61929 6.125 6.5 5.00571 6.5 3.625C6.5 2.24429 7.61929 1.125 9 1.125C10.3807 1.125 11.5 2.24429 11.5 3.625ZM16.5 6.125C16.5 7.16053 15.6605 8 14.625 8C13.5895 8 12.75 7.16053 12.75 6.125C12.75 5.08947 13.5895 4.25 14.625 4.25C15.6605 4.25 16.5 5.08947 16.5 6.125ZM5.25 6.125C5.25 7.16053 4.41053 8 3.375 8C2.33947 8 1.5 7.16053 1.5 6.125C1.5 5.08947 2.33947 4.25 3.375 4.25C4.41053 4.25 5.25 5.08947 5.25 6.125Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}