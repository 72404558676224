import React from 'react';

const range = (start: number, end: number) => {
    return [...Array(end - start).keys()].map((el) => el + start);
}

const getPagesCut = (pagesCount: number, pagesCutCount: number, currentPage: number) => {
    const ceiling = Math.ceil(pagesCutCount / 2)
    const floor = Math.floor(pagesCutCount / 2)
    if (pagesCount < pagesCutCount) {
        return { start: 1, end: pagesCount + 1 }
    }
    else if (currentPage >= 1 && currentPage <= ceiling) {
        return { start: 1, end: pagesCutCount + 1 }
    }
    else if (currentPage + floor >= pagesCount) {
        return { start: pagesCount - pagesCutCount + 1, end: pagesCount + 1 }
    }
    else {
        return { start: currentPage - ceiling + 1, end: currentPage + floor + 1 }
    }
}

type PageType = number | '<' | '>' | '<<' | '>>';

interface PaginationItemProps {
    page: PageType;
    currentPage: number;
    onPageChange: (newPage: number) => void;
    isDisabled?: boolean;
}

const PaginationItem = ({ page, currentPage, onPageChange, isDisabled }: PaginationItemProps) => {

    const handleClick = () => {
        if (isDisabled) return;

        let newPage: number;
        switch (page) {
            case '<':
                newPage = currentPage - 1;
                break;
            case '>':
                newPage = currentPage + 1;
                break;
            case '<<':
                newPage = 1;
                break;
            case '>>':
                newPage = currentPage;
                break;
            default:
                newPage = page;
        }
        onPageChange(newPage);
    };

    return (
        <button
            className={`inline ${isDisabled ? "cursor-not-allowed" : ""}`}
            onClick={handleClick}
            disabled={isDisabled}
        >
            <span className={` block px-2 py-1 border-2 rounded-lg border-background  text-text-secondary
                ${page === currentPage ? "bg-primary text-white" : ""}
                ${!isDisabled && (page !== currentPage) ? "text-text-secondary bg-white hover:bg-gray-50" : ""}
                ${isDisabled ? "bg-slate-200" : ""}`}>
                {page}
            </span>
        </button>
    );
}

interface PaginationProps {
    page: number;
    totalCount: number;
    pageSize: number;
    onPageChange: (newPage: number) => void;
}

export const Pagination = ({ page: currentPage, totalCount, onPageChange, pageSize }: PaginationProps) => {
    const pagesCount = Math.ceil(totalCount / pageSize);
    const pagesCut = getPagesCut(pagesCount, 4, currentPage);
    const pages = range(pagesCut.start, pagesCut.end);
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === pagesCount;

    return (
        <ul className='flex gap-2'>
            <PaginationItem
                page="<<"
                currentPage={currentPage}
                onPageChange={() => onPageChange(1)}
                isDisabled={isFirstPage} />
            <PaginationItem
                page="<"
                currentPage={currentPage}
                onPageChange={() => onPageChange(currentPage - 1)}
                isDisabled={isFirstPage} />

            {pages.map((page) => (
                <PaginationItem
                    key={page}
                    page={page}
                    currentPage={currentPage}
                    onPageChange={onPageChange} />
            ))}

            <PaginationItem
                page=">"
                currentPage={currentPage}
                onPageChange={() => onPageChange(currentPage + 1)}
                isDisabled={isLastPage} />

            <PaginationItem
                page=">>"
                currentPage={currentPage}
                onPageChange={() => onPageChange(pagesCount)}
                isDisabled={isLastPage} />
        </ul>
    )
}
