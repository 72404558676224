
import PropTypes from 'prop-types';
import React, { ComponentType, ReactElement } from 'react';

interface ButtonProps {
  text: string;
  textColor?: string;
  icon?: ComponentType<{ className?: string }>; // Permite un componente que acepta `className`
  bgColor?: string;
  hasBorder?: boolean;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button = ({ text, icon: Icon, bgColor, hasBorder, textColor, isDisabled, ...props }: ButtonProps): ReactElement => {
  return (
    <button

      disabled={isDisabled}
      className={`${bgColor ? bgColor : "bg-primary"} ${hasBorder ? "border-2" : ""} whitespace-nowrap rounded-full border-primary-light shadow-md text-base flex items-center justify-center gap-2 font-semibold  w-full py-2 px-4
        `}
      {...props}>
      <span className={` ${textColor ? textColor : "text-white"}`}>{text}</span>
      {Icon && <Icon className="w-4 h-4" />} {/* Asegúrate de que `Icon` es un componente React */}
    </button>
  );
};


export default Button;
