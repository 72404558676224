import React from 'react'

interface EyeIconProps {
  width?: number
  height?: number
  color?: string
}

export default function EyeIcon({ width = 22, height = 18, color = "#64758B" }: EyeIconProps = {}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.03526 9.3224C0.966176 9.11513 0.96611 8.89073 1.03507 8.68343C2.42343 4.50972 6.3605 1.5 11.0005 1.5C15.6384 1.5 19.5739 4.50692 20.964 8.6776C21.0331 8.88487 21.0332 9.10927 20.9642 9.31658C19.5759 13.4903 15.6388 16.5 10.9988 16.5C6.3609 16.5 2.42535 13.4931 1.03526 9.3224Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9997 9C13.9997 10.6569 12.6566 12 10.9997 12C9.34285 12 7.99971 10.6569 7.99971 9C7.99971 7.34315 9.34285 6 10.9997 6C12.6566 6 13.9997 7.34315 13.9997 9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}