import React from 'react'

interface ShieldIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

export default function ShieldIcon({ size = 60, ...props }: ShieldIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2107 5.42427C29.9338 4.73944 31.0662 4.73944 31.7893 5.42427C36.8312 10.1992 43.6344 13.125 51.125 13.125C51.2442 13.125 51.3632 13.1243 51.482 13.1228C52.3004 13.1126 53.0308 13.6345 53.2865 14.412C54.3178 17.5489 54.875 20.8988 54.875 24.3751C54.875 39.2302 44.7149 51.7075 30.9674 55.2458C30.6608 55.3247 30.3392 55.3247 30.0326 55.2458C16.2851 51.7075 6.125 39.2302 6.125 24.3751C6.125 20.8988 6.68218 17.5489 7.71354 14.412C7.96917 13.6345 8.69961 13.1126 9.51802 13.1228C9.63683 13.1243 9.75582 13.125 9.875 13.125C17.3656 13.125 24.1688 10.1992 29.2107 5.42427ZM30.5 20.625C31.5355 20.625 32.375 21.4645 32.375 22.5V31.875C32.375 32.9105 31.5355 33.75 30.5 33.75C29.4645 33.75 28.625 32.9105 28.625 31.875V22.5C28.625 21.4645 29.4645 20.625 30.5 20.625ZM30.5 37.5C29.4645 37.5 28.625 38.3395 28.625 39.375V39.3937C28.625 40.4293 29.4645 41.2687 30.5 41.2687H30.5188C31.5543 41.2687 32.3938 40.4293 32.3938 39.3937V39.375C32.3938 38.3395 31.5543 37.5 30.5188 37.5H30.5Z"
        fill="#DC2625"
      />
    </svg>
  )
}