import { useEffect, useRef, useState } from 'react';
import { Organization } from '../interfaces/GetMeRequest';
import { useLocalStorage } from '../hooks/useLocalStorage';
import DownArrowIcon from './Icons/DownArrowIcon';
import CheckCircle from './Icons/CheckCircle';
import { OrganizationIcon } from './Icons/OrganizationIcon';

interface OrganizationSelectorProps {
    organizations: Organization[];
    selectedOrg: Organization | null;
    onSelect: (org: Organization) => void;
    isLoading: boolean;
    isError: boolean;
}

export default function OrganizationSelector({ organizations, selectedOrg, onSelect, isLoading, isError }: OrganizationSelectorProps) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading organizations</div>;
    }

    return (
        <div className=" relative max-w-56 " ref={dropdownRef}>
            <div className='size-full flex justify-center items-center'>
                <button
                    className="size-full h-full px-4 text-left"
                    onClick={() => setIsOpen(!isOpen)}
                    aria-haspopup="listbox"
                    aria-expanded={isOpen}

                >
                    {selectedOrg ? (
                        <span className="flex items-center justify-center gap-3">
                            {selectedOrg.urlImg ? (
                                <img src={selectedOrg.urlImg} alt="" className="w-5 h-5 mr-3 rounded" />
                            ) : (
                                <div className="flex items-center justify-center p-1 mr-3  bg-gray-100 rounded" >

                                    <OrganizationIcon size={20} color="#101216" />
                                </div>
                            )}
                            <p className='text-text-secondary font-bold'>{selectedOrg.name}</p>
                            <DownArrowIcon size={20} color="#101216" />
                        </span>
                    ) : (
                        "Not logged in.."
                    )}
                </button>
            </div>

            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">

                    <ul className="max-h-60 overflow-auto border-[1px] rounded-md" role="listbox">
                        {organizations.map((org, index) => (
                            <li
                                key={org.id}
                                className={`px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-3 ${index !== organizations.length - 1 ? 'border-b-2' : ''
                                    }`}

                                onClick={() => onSelect(org)}
                                role="option"
                                aria-selected={selectedOrg?.id === org.id}
                            >
                                {org.urlImg ? (
                                    <img src={org.urlImg} alt="" className="w-5 h-5 mr-3 rounded" />
                                ) : (
                                    <div className="flex items-center justify-center p-1 mr-3  bg-gray-100 rounded" >

                                        <OrganizationIcon size={20} color="#101216" />
                                    </div>

                                )}
                                <p className='text-text-secondary font-bold'>{org.name}</p>
                                {
                                    selectedOrg?.id === org.id && <CheckCircle size={20} />
                                }
                            </li>
                        ))}
                        {organizations.length === 0 && (
                            <li className="px-4 py-2 text-gray-500">No matching organizations found</li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
}