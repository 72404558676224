import React from "react";
import { calcularDiferenciaDias } from "./calcularDiferenciaDias";

export const fechaActualizacionDocumentos = (
  fechaObjetivo: string | null,
): number | string => {
  if (!fechaObjetivo) {
    return "Fecha no disponible";
  }

  const diasTranscurridos = calcularDiferenciaDias(fechaObjetivo);

  if (diasTranscurridos > 30) {
    // Convertir la fecha a formato dd/mm/yyyy
    const fecha = new Date(fechaObjetivo);
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const anio = fecha.getFullYear();
    return `Actualizado el ${dia}/${mes}/${anio}`;
  } else if (diasTranscurridos === 0) {
    return "Actualizado hoy";
  } else {
    return `Actualizado hace ${diasTranscurridos} día${diasTranscurridos === 1 ? "" : "s"}`;
  }
};
