import { Outlet } from "react-router-dom"
import TopNav from "../components/TopNav"


export const TopNavLayout = () => {
  return (
    <div>
      <TopNav />
      <Outlet />
    </div>
  )
}
