import React from 'react'

interface InboxIconProps {
    className?: string
    size?: number
}

export default function InboxIcon({ className = '', size = 22 }: InboxIconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.199951 10.5251C0.199951 10.2285 0.239919 9.93335 0.318776 9.6475L2.14635 3.02253C2.54114 1.59142 3.84296 0.600098 5.32753 0.600098H16.6724C18.1569 0.600098 19.4588 1.59142 19.8535 3.02253L21.6811 9.6475C21.76 9.93335 21.7999 10.2285 21.7999 10.5251V15.0001C21.7999 16.3256 20.7254 17.4001 19.4 17.4001H2.59995C1.27447 17.4001 0.199951 16.3256 0.199951 15.0001V10.5251ZM3.88154 3.50121C4.06099 2.8507 4.65273 2.4001 5.32753 2.4001H16.6724C17.3472 2.4001 17.9389 2.8507 18.1184 3.5012L19.9459 10.1262C19.9527 10.1507 19.9588 10.1753 19.9643 10.2001H15.8C15.3765 10.2001 14.9843 10.4233 14.7681 10.7875L14.0406 12.0127C13.8244 12.3769 13.4323 12.6001 13.0088 12.6001H8.88323C8.42871 12.6001 8.01319 12.3433 7.80992 11.9368L7.27326 10.8634C7.06999 10.4569 6.65448 10.2001 6.19995 10.2001H2.03557C2.04107 10.1753 2.0472 10.1507 2.05396 10.1262L3.88154 3.50121Z"
                fill="currentColor"
            />
        </svg>
    )
}