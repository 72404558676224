import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { ExternalLogin } from "../pages/ExternalLogin";
import Validation from "../pages/Validation";
import { PrivateRoutes } from "./PrivateRoutes";
import { ROUTES } from "./routes";
export const AppRouter = () => {
  const { isAuthenticated, isAuthLoading } = useAuthContext();

  if (isAuthLoading) {
    return <div>Loading...</div>; // Mientras se verifica la autenticación, muestra un indicador de carga
  }

  return (
    <Router>
      <Routes>
        {/* Ruta pública: Login */}
        <Route
          path={ ROUTES.EXTERNALLOGIN }
          element={
            !isAuthenticated ? (
              <ExternalLogin />
            ) : (
              <Navigate to={ROUTES.VISOR_DOCUMENTOS} replace />
            )
          }
        />
        <Route
          path={`${ROUTES.VALIDACION}/:signProcessId/:organization`}
          element={<Validation />}
        />

        {/* Ruta privada: Si está autenticado */}
        <Route
          path="*"
          element={
            isAuthenticated ? (
              <PrivateRoutes />
            ) : (
              <Navigate to={ ROUTES.EXTERNALLOGIN } replace />
            )
          }
        />
      </Routes>
    </Router>
  );
};
