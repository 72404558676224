import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useParams } from "react-router-dom";
import api from "../api/Axios";
import { DocumentEndpoints } from "../api/services/documentService/config";
import Check from "../assets/icons/check-circle.svg";
import EuroInnovaLogo from "../assets/icons/euroInnovaLogo.svg";
import InnovaNet from "../assets/icons/innovanet.svg";
import ClockIcon from "../components/Icons/ClockIcon";
import ShieldIcon from "../components/Icons/ShieldIcon";
import Button from "../components/ui/Button";
import { ValidationDocumentResponse } from "../interfaces/ValidationDocumentResponse";
import FormatearFechaATexto from "../utils/dates/FormatearFechaATexto";
import formatearFechaAHora from "../utils/dates/formatearFechaAHora";
import { TranslationKeys } from "../utils/i18n/TranslationKeys";



// Definición del enum
const EnumValues = {
  EuroInnova: 1,
  Innotutor: 2,
  DEFAULT: 'DEFAULT'
};

export default function Validation() {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number | null>(null);
  const { signProcessId, organization } = useParams(); // Obtén ambos parámetros de la URL

  const { data: documentState, error, isLoading, isFetching, refetch } = useQuery<ValidationDocumentResponse>({
    queryFn: async () => {
      const response = await api.get<ValidationDocumentResponse>(DocumentEndpoints.getDocumentStatus(signProcessId));
      return response.data;
    },
    queryKey: ["documentStatus"],

  });

  // Determina la imagen a mostrar basada en el valor del enum
  const getImageSrc = (value: number) => {
    switch (value) {
      case EnumValues.EuroInnova:
        return EuroInnovaLogo;

      case EnumValues.Innotutor:
        return InnovaNet;

      default:
        return EuroInnovaLogo; // Imagen por defecto
    }
  };


  return (
    <div className="h-screen w-screen ">
      <div className="flex flex-col items-center w-full  h-full px-8 py-6 gap-5 md:flex md:flex-row md:justify-evenly  md:items-center ">
        <div className="flex flex-col gap-5 h-2/3 justify-center items-center w-full md:w-2/3 md:h-full">
          <img src={getImageSrc(Number(organization))} alt="Imagen organizacion" className="w-48 md:w-3/5 max-h-16" />
          <div className="bg-muted rounded-md w-full h-[60vh] overflow-hidden">
            {documentState?.pdfBase64 && (
              <iframe
                src={`data:application/pdf;base64,${documentState.pdfBase64}`}
                className="w-full h-full"
                title="Documento PDF"
              />
            )}
          </div>
          <p className="font-bold">{documentState?.fileName}</p>
          <p className="text-text-secondary text-sm">{signProcessId}</p>
        </div>
        <div className="w-[70px] h-[3px] bg-neutral-200 md:hidden">

        </div>
        <div className="  w-full md:w-auto h-1/2 md:h-full">
          {isLoading ? (
            <div className="w-8 h-8 border-4 border-blue-500 border-dashed rounded-full animate-spin items-center"></div>
          )
            : documentState?.isDocumentValid && documentState?.isDocumentSigned ? (
              <div className=" flex flex-col justify-center items-center h-full gap-6">
                <div className="  w-auto h-full basis-10/12 flex flex-col items-center justify-center  gap-6">
                  <img src={Check} alt="check" className="" />
                  <span className=" font-bold">{t(TranslationKeys.DocumentoFirmado)}</span>
                  <p className=" text-text-secondary text-sm text-center"><Trans
                    i18nKey={`${TranslationKeys.SeEnvioElXyFirmadoElX}`}
                    values={{
                      creationDate: FormatearFechaATexto(documentState?.creationDate ? documentState.creationDate : null),
                      signedDate: FormatearFechaATexto(documentState?.signedDate ? documentState.signedDate : null),
                    }}
                    components={{ strong: <strong /> }}
                  /> </p>
                </div>

              </div>
            )
              : !documentState?.isDocumentValid ? (
                <div className=" flex flex-col items-center justify-center h-full gap-6">
                  <ShieldIcon size={50} aria-label="Security shield icon" />
                  <p className="font-bold">{t(TranslationKeys.DocumentoNoValido)}</p>
                  <p className="text-text-secondary text-sm text-center break-words">{t(TranslationKeys.DocumentoNoEmitido)}</p>
                  <div className=" ">
                    <Button
                      text={t(TranslationKeys.VolverAIntentar)}
                      textColor="text-primary"
                      bgColor="bg-white"
                      onClick={() => refetch()}
                    />
                  </div>
                </div>
              ) : (
                <div className=" flex flex-col items-center justify-center h-full gap-6">
                  <ClockIcon />
                  <p className=" font-bold">{t(TranslationKeys.DocumentoPendienteDeFirmar)}</p>
                  <p className=" text-text-secondary text-sm text-center">
                    <Trans
                      i18nKey={`${TranslationKeys.DocumentoEnviadoConFecha}`}
                      values={{ date: FormatearFechaATexto(documentState?.creationDate ? documentState.creationDate : null), time: formatearFechaAHora(documentState?.creationDate ? documentState.creationDate : null) }}
                      components={{ strong: <strong />, br: <br /> }}
                    />
                  </p>
                </div>

              )}
        </div>
      </div>


    </div>



  );
}