import { FallbackLng } from "./../../../node_modules/i18next/typescript/options.d";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLang from "./locales/en/en.json";
import esLang from "./locales/es/es.json";
import itLang from "./locales/it/it.json";
import LanguageDetector from "i18next-browser-languagedetector";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: enLang,
  },
  it: {
    translation: itLang,
  },
  es: {
    translation: esLang,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "es",
    detection: {
      order: ["navigator", "htmlTag", "path", "subdomain"],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
