import Cookies from "js-cookie";
import { useMutation } from "@tanstack/react-query";
import api from "../../api/Axios";
import { SecurityEndpoints } from "../../api/services/documentService/config";

interface RefreshResponse {
  accessToken: string;
  refreshToken: string;
}

// Función de mutación para obtener el nuevo token
const refreshTokenMutation = async (): Promise<RefreshResponse> => {
  const refreshToken = Cookies.get("refreshToken");

  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  const { data } = await api.post<RefreshResponse>(
    SecurityEndpoints.refreshToken(refreshToken),
  );
  Cookies.set("accessToken", data.accessToken, { expires: 1 / 24 }); // Expira en 1 hora
  Cookies.set("refreshToken", data.refreshToken, { expires: 7 }); // Expira en 1 hora
  return data;
};

// Hook personalizado que utiliza useMutation
export const useRefreshToken = () => {
  return useMutation({
    mutationFn: refreshTokenMutation,
    onSuccess: (data) => {
      Cookies.set("accessToken", data.accessToken, { expires: 1 / 24 }); // Expira en 1 hora
      Cookies.set("refreshToken", data.refreshToken, { expires: 7 }); // Expira en 1 hora
    },
    onError: () => {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
    },
  });
};

// Función asíncrona para refrescar el token sin usar hooks de React
export const refreshToken = async (): Promise<string> => {
  try {
    const response = await refreshTokenMutation();
    return response.accessToken;
  } catch (error) {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    throw error;
  }
};
