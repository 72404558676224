import React from "react";
import { AppRouter } from "./router/Router";
import { AuthContextProvider } from "./context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import "./utils/i18n/i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppContextProvider } from "./context/AppContext";
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <AppContextProvider>
          <AppRouter />
        </AppContextProvider>

        <ReactQueryDevtools />
      </AuthContextProvider>
    </QueryClientProvider>
  );
}
export default App;
