import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DocumentEndpoints } from "../api/services/documentService/config";
import api from "../api/Axios";
import { all } from "axios";
import { AllDocument, CompletedDocument, DocumentBase, DocumentListResponse, DocumentStatusEnum, IDocumentListResponse, InProgressDocument, PendingDocument } from "../interfaces/DocumentsByUserRequest";

export interface FilterDocument extends DocumentBase {
    type: DocumentStatusEnum;
}

export interface DocumentosContextType {
    viendo: 'pending' | 'completed' | 'inProgress' | 'all';
    completedDocuments: CompletedDocument[];
    inProgressDocuments: InProgressDocument[];
    pendingDocuments: PendingDocument[];
    allDocuments: AllDocument[];
    documentosFiltrados: FilterDocument[];
    aplicarFiltro: (nuevoFiltro: 'pending' | 'completed' | 'inProgress' | 'all') => void
    handleSelectAll: () => void;
    handleUnSelectAll: () => void;
    handleDocumentSelect: (documentId: string) => void;
    selectedDocuments: string[];
    pageAll: number;
    pagePending: number;
    pageInProgress: number;
    pageCompleted: number;
    pageSize: number;
    totalCountAll: number;
    totalCountPending: number;
    totalCountInProgress: number;
    totalCountCompleted: number;
    setPageAll: (page: number) => void;
    setPageCompleted: (page: number) => void;
    setPageInProgress: (page: number) => void;
    setPagePending: (page: number) => void;
    refetchDocuments: (newPage: number) => void;
    refetchPage: () => void,
    search: string | null;
    setSearch: (search: string) => void;
    isAllSelected: boolean;
}

export const DocumentosContext = createContext<DocumentosContextType>({
    viendo: 'all',
    completedDocuments: [],
    inProgressDocuments: [],
    pendingDocuments: [],
    allDocuments: [],
    documentosFiltrados: [],
    aplicarFiltro: () => { },
    handleSelectAll: () => { },
    handleDocumentSelect: () => { },
    handleUnSelectAll: () => { },
    selectedDocuments: [],
    pageAll: 1,
    pageCompleted: 1,
    pagePending: 1,
    pageInProgress: 1,
    totalCountCompleted: 0,
    totalCountPending: 0,
    totalCountInProgress: 0,
    totalCountAll: 0,
    pageSize: 10,
    setPageAll: () => { },
    setPageCompleted: () => { },
    setPageInProgress: () => { },
    setPagePending: () => { },
    refetchDocuments: (newPage: number = 0) => { },
    refetchPage: () => { },
    search: null,
    setSearch: (search: string) => { },
    isAllSelected: false
});

export const DocumentosContextProvider = () => {

    const queryClient = useQueryClient();
    const [inProgressDocuments, setInProgressDocuments] = useState<InProgressDocument[]>([]);
    const [pendingDocuments, setPendingDocuments] = useState<PendingDocument[]>([]);
    const [completedDocuments, setCompletedDocuments] = useState<CompletedDocument[]>([]);
    const [allDocuments, setAllDocuments] = useState<AllDocument[]>([]);
    const [pageAll, setPageAll] = useState<number>(1);
    const [pageInProgress, setPageInProgress] = useState<number>(1);
    const [pagePending, setPagePending] = useState<number>(1);
    const [pageCompleted, setPageCompleted] = useState<number>(1);
    const pageSize = 10;
    const [totalCountCompleted, setTotalCountCompleted] = useState<number>(0);
    const [totalCountPending, setTotalCountPending] = useState<number>(0);
    const [totalCountInProgress, setTotalCountInProgress] = useState<number>(0);
    const [totalCountAll, setTotalCountAll] = useState<number>(0);
    const [viendo, setViendo] = useState<'pending' | 'inProgress' | 'completed' | 'all'>('pending');
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
    const [search, setSearch] = useState<string | null>(null)

    const getDocuments = async (pageAll: number, pageCompleted: number, pageInProgress: number, pagePending: number, pageSize: number, search: string | null): Promise<DocumentListResponse> => {

        if (search) {
            const response = await api.get(DocumentEndpoints.getDocumentList(pageAll, pageCompleted, pagePending, pageInProgress, pageSize, search));
            return response.data;
        } else {
            const response = await api.get(DocumentEndpoints.getDocumentList(pageAll, pageCompleted, pagePending, pageInProgress, pageSize));
            return response.data;
        }
    };

    const { data, error, isLoading, isFetching, refetch } = useQuery<DocumentListResponse>({
        queryFn: () => getDocuments(pageAll, pageCompleted, pageInProgress, pagePending, pageSize, search),
        queryKey: ["documentList", pageAll, pageCompleted, pageInProgress, pagePending, pageSize, search],
    });

    const refetchDocuments = async (newPage: number = 1) => {
        setPageAll(newPage);
        setPageCompleted(newPage);
        setPageInProgress(newPage);
        setPagePending(newPage);
        await refetch();
    };

    const refetchPage = async () => {
        await refetch();
    }

    const addStatus = (docs: DocumentBase[], status: DocumentStatusEnum): FilterDocument[] => {
        if (status === DocumentStatusEnum.All) {
            if (isAllDocumentArray(docs)) {
                return docs.map(doc => ({
                    ...doc,
                    type: doc.documentStatus
                }));
            }
        }
        return docs.map(doc => ({ ...doc, type: status }));
    }

    // Función de guarda de tipo para verificar si el array es de tipo AllDocument[]
    function isAllDocumentArray(docs: DocumentBase[] | AllDocument[]): docs is AllDocument[] {
        return docs.length > 0 && 'documentStatus' in docs[0];
    }


    const documentosFiltrados: FilterDocument[] = (() => {
        switch (viendo) {
            case 'completed':
                return addStatus(completedDocuments, DocumentStatusEnum.Completed);
            case 'pending':
                return addStatus(pendingDocuments, DocumentStatusEnum.Pending);
            case 'inProgress':
                return addStatus(inProgressDocuments, DocumentStatusEnum.InProgress);
            case 'all':
                return addStatus(allDocuments, DocumentStatusEnum.All);
            default:
                return addStatus(allDocuments, DocumentStatusEnum.All);
        }
    })();


    useEffect(() => {
        if (data) {
            setTotalCountCompleted(data.completedDocuments?.totalCount ?? 0);
            setTotalCountPending(data.pendingDocuments?.totalCount ?? 0);
            setTotalCountInProgress(data.inProgressDocuments?.totalCount ?? 0);
            setTotalCountAll(data.allDocuments?.totalCount ?? 0);
            setInProgressDocuments(data.inProgressDocuments?.documents ?? []);
            setPendingDocuments(data.pendingDocuments?.documents ?? []);
            setCompletedDocuments(data.completedDocuments?.documents ?? []);
            setAllDocuments(data.allDocuments?.documents ?? []);
        }

    }, [data]);


    const pendingDocumentsInAll = useMemo(() => {
        if (viendo === 'all') {
            const pendingIds = new Set(pendingDocuments.map(doc => doc.id));
            return allDocuments.filter(doc => pendingIds.has(doc.id));
        }
        return pendingDocuments;
    }, [allDocuments, pendingDocuments, viendo]);

    const isAllSelected = useMemo(() => {
        if (viendo === 'all') {
            return selectedDocuments.length === pendingDocumentsInAll.length && pendingDocumentsInAll.length > 0;
        } else {
            return selectedDocuments.length === pendingDocuments.length && pendingDocuments.length > 0;
        }
    }, [selectedDocuments, pendingDocumentsInAll, pendingDocuments, viendo]);

    const handleSelectAll = useCallback(() => {
        if (viendo === 'all') {
            if (selectedDocuments.length === pendingDocumentsInAll.length) {
                setSelectedDocuments([]);
            } else {
                setSelectedDocuments(pendingDocumentsInAll.map(doc => doc.id));
            }
        } else {
            if (selectedDocuments.length === pendingDocuments.length) {
                setSelectedDocuments([]);
            } else {
                setSelectedDocuments(pendingDocuments.map(doc => doc.id));
            }
        }
    }, [selectedDocuments, pendingDocumentsInAll, pendingDocuments, viendo]);
    const handleUnSelectAll = () => {
        setSelectedDocuments([]);
    };
    const handleDocumentSelect = (documentId: string) => {
        setSelectedDocuments(prevSelected =>
            prevSelected.includes(documentId)
                ? prevSelected.filter(id => id !== documentId)
                : [...prevSelected, documentId]
        );
    };


    const aplicarFiltro = (nuevoFiltro: 'pending' | 'inProgress' | 'completed' | "all") => {
        setPageCompleted(1);
        setPageInProgress(1);
        setPagePending(1);
        setPageAll(1);
        handleUnSelectAll();
        setViendo(nuevoFiltro);
    };

    useEffect(() => {
        // Refetch en el cambio de página
        console.log("refetchDocuments");
        console.log(pageAll, pageCompleted, pageInProgress, pagePending);
        refetch();
    }, [pageAll, pageCompleted, pageInProgress, pagePending, refetch]);


    return (
        <DocumentosContext.Provider value={{
            allDocuments: allDocuments,
            completedDocuments: completedDocuments,
            inProgressDocuments: inProgressDocuments,
            pendingDocuments: pendingDocuments,
            documentosFiltrados: documentosFiltrados,
            viendo,
            aplicarFiltro,
            handleSelectAll,
            handleDocumentSelect,
            handleUnSelectAll,
            selectedDocuments,
            pageAll,
            pageCompleted,
            pageInProgress,
            pagePending,
            pageSize,
            totalCountAll,
            totalCountCompleted,
            totalCountPending,
            totalCountInProgress,
            refetchDocuments,
            setPageAll,
            setPageCompleted,
            setPageInProgress,
            setPagePending,
            search,
            setSearch,
            isAllSelected,
            refetchPage
        }}>
            <Outlet />
        </DocumentosContext.Provider>
    )
}

export const useDocumentosContext = () => {

    const context = useContext(DocumentosContext);

    if (context === undefined) {
        throw new Error('useDocumentosContext must be used within a DocumentosContextProvider');
    }
    return context;
}
