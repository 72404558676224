
import { useQuery } from '@tanstack/react-query';
import { FilterDocument, useDocumentosContext } from '../../context/DocumentosContext';
import { DocumentBase, DocumentStatusEnum } from '../../interfaces/DocumentsByUserRequest';
import { fechaActualizacionDocumentos } from '../../utils/dates/fechaActualizacionDocumentos';
import CheckCircle from '../Icons/CheckCircle';
import ErrorIcon from '../Icons/ErrorIcon';
import EyeIcon from '../Icons/Eye-icon';
import Fingerprint from '../Icons/Fingerprint';
import InfoIcon from '../Icons/InfoIcon';
import { Checkbox } from '../ui/CheckBox';
import api from '../../api/Axios';
import { DocumentEndpoints } from '../../api/services/documentService/config';
import { useState } from 'react';

export interface ItemListaDocumentosProps {
    documento: FilterDocument;
    isSelected?: boolean;
    onDocumentSelect?: (documentId: string) => void;
}

export const ItemListaDocumentos = ({ documento, isSelected, onDocumentSelect }: ItemListaDocumentosProps) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const { refetch, isFetching } = useQuery<Blob>({
        queryFn: async () => {
            console.log('Fetching document:', documento.id);
            const response = await api.get<Blob>(DocumentEndpoints.getFile(documento.id), {
                responseType: 'blob'
            });
            console.log('Document fetched:', response.status);
            return response.data;
        },
        queryKey: ["getFile", documento.id],
        enabled: false
    });

    const handleDownload = async () => {
        setIsDownloading(true);
        try {
            console.log('Starting download process');
            const result = await refetch();
            console.log('Refetch result:', result.status);
            if (result.data) {
                console.log('Blob received, size:', result.data.size);
                const blob = new Blob([result.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                console.log('Blob URL created:', url);
                const link = document.createElement('a');
                link.href = url;
                let fileName = documento.documentName.toLowerCase().endsWith('.pdf')
                    ? documento.documentName
                    : `${documento.documentName}.pdf`;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                console.log('Triggering download');
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                console.log('Download process completed');
            } else {
                console.error('No data received from refetch');
            }
        } catch (err) {
            console.error('Error downloading file:', err);
            alert('Error al descargar el archivo. Por favor, inténtelo de nuevo.');
        } finally {
            setIsDownloading(false);
        }
    };
    return (
        <div className='flex justify-between w-full pl-2 pr-4 py-2 border mb-3 border-slate-200 rounded-lg'>
            <div className='flex items-center '>

                <Checkbox
                    checked={isSelected}
                    disabled={documento.type !== DocumentStatusEnum.Pending ? true : false}
                    onChange={() => onDocumentSelect && onDocumentSelect(documento.id)}
                />

                <div className='flex flex-col gap-1 px-4'>
                    <div className='flex items-center gap-4'>
                        <p className='text-base font-bold'>{documento.documentName}</p>
                        <div className='hidden md:block rounded-lg bg-slate-100 px-2 py-1'>

                            <p className=' text-sm text-text-secondary'>{documento.id}.pdf</p>
                        </div>

                    </div>
                    <div className='flex gap-1 items-center'>
                        {(() => {
                            switch (documento.type) {
                                case DocumentStatusEnum.Pending:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <Fingerprint />
                                            <span className="text-sm text-text-secondary">Pendiente de firmar</span>
                                        </div>
                                    );
                                case DocumentStatusEnum.InProgress:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <InfoIcon size={16} />
                                            <span className="text-sm text-text-secondary">Pendiente de otros firmantes</span>
                                        </div>
                                    );
                                case DocumentStatusEnum.Completed:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <CheckCircle />
                                            <span className="text-sm text-text-secondary">Completado</span>
                                        </div>
                                    );
                                default: // Manejo de otros estados o errores
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <ErrorIcon size={20} />
                                            <span className="text-sm text-text-secondary">Mensajes de error</span>
                                        </div>
                                    );
                            }
                        })()}
                    </div>
                </div>
            </div>
            <div className='flex items-center gap-4'>
                <p className='hidden md:block text-text-secondary text-sm'>{fechaActualizacionDocumentos(documento.updatedDate?.toString() ?? "")}</p>
                <button
                    className='relative'
                    onClick={handleDownload}
                    disabled={isDownloading || isFetching}
                >
                    {isDownloading || isFetching ? (
                        <EyeIcon color='#d6d6d6' />
                    ) : (
                        <EyeIcon />
                    )}
                </button>
            </div>
        </div>

    )
}
