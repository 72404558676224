// Interfaces
export interface IPagination {
  page: number;
  pageSize: number;
  totalCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface DocumentBase {
  id: string;
  documentName: string;
  signProcessId: string;
  updatedDate?: Date;
  creationDate: Date;
}

export interface IDocumentListResponse<T extends DocumentBase>
  extends IPagination {
  documents: T[];
}

export interface AllDocument extends DocumentBase {
  documentStatus: DocumentStatusEnum;
}

export interface InProgressDocument extends DocumentBase {}
export interface PendingDocument extends DocumentBase {}

export interface CompletedDocument extends DocumentBase {}

export interface DocumentListResponse {
  allDocuments: IDocumentListResponse<AllDocument>;
  pendingDocuments: IDocumentListResponse<PendingDocument>;
  inProgressDocuments: IDocumentListResponse<InProgressDocument>;
  completedDocuments: IDocumentListResponse<CompletedDocument>;
}

// Enum
export enum DocumentStatusEnum {
  All,
  Pending,
  InProgress,
  Completed,
}
