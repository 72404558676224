import React from 'react'

interface InfoIconProps {
    size?: number
    color?: string
    className?: string
}

export default function InfoIcon({
    size = 16,
    color = "#F97315",
    className = ""
}: InfoIconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3C8.41421 3 8.75 3.33579 8.75 3.75V8.25C8.75 8.66421 8.41421 9 8 9C7.58579 9 7.25 8.66421 7.25 8.25V3.75C7.25 3.33579 7.58579 3 8 3ZM8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44771 11 7 11.4477 7 12C7 12.5523 7.44771 13 8 13Z"
                fill={color}
            />
        </svg>
    )
}