import { error } from "console";
import { useState } from "react";

export function useLocalStorage(key?: string) {
    const isKeyValid = (key: string | undefined): key is string => {
        return typeof key === 'string' && key.length > 0;
    };
    const setItem = (value: unknown) => {
        if (!isKeyValid(key)) {
            console.error("Invalid key provided to setItem");
            return;
        }
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.log(error)
        }
    };

    const getItem = () => {
        if (!isKeyValid(key)) {
            console.error("Invalid key provided to setItem");
            return;
        }
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : undefined;

        } catch (error) {
            console.log(error);
        }
    }

    const removeItem = () => {
        if (!isKeyValid(key)) {
            console.error("Invalid key provided to setItem");
            return;
        }
        try {
            window.localStorage.removeItem(key);
        } catch (error) {
            console.log(error);
        }
    };

    const clearAll = () => {
        try {
            window.localStorage.clear();
        } catch (error) {
            console.error("Error clearing localStorage:", error);
        }
    };

    return { setItem, getItem, removeItem, clearAll };
}