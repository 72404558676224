interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder: string;
  type: "text" | "number" | "password";
  value: string;
}

const InputField = ({ label, placeholder, type, value, ...props }: InputFieldProps) => {
  return (
    <div className='flex flex-col justify-between items-start flex-nowrap gap-2'>
      {label && <label htmlFor={label} className="text-lg font-sans font-bold leading-6 text-text">{label}</label>}
      <div className="bg-background w-full border-none rounded-md shadow-sm focus-within:ring-1 focus-within:ring-inset focus-within:ring-background-dark">
        <input
          type={type}
          name={label}
          id={label}
          className="py-1.5 pl-3 w-full bg-transparent text-text placeholder:text-text-secondary sm:text-sm sm:leading-6 outline-none focus:border-2 focus:border-primary rounded-md  transition-colors duration-200 ease-in-out"
          placeholder={placeholder}
          value={value}
          {...props}
        />
      </div>
    </div>
  );
};

export default InputField;